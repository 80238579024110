import { Component, OnInit } from '@angular/core';
import { AuthServService } from 'src/app/core/auth/auth-serv.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {

  // User profile
  profile: any;

  constructor(public auth: AuthServService) { }

  ngOnInit() {
    // if (this.auth.userProfile) {
    //   this.profile = this.auth.userProfile;
    // } else {
    //   this.auth._getProfile((err, profile) => {
    //     this.profile = profile;
    //   });
    // }
  }

}
